import request from './request'

const api = {
	// 获取列表
	lists: '/gh/Elect/getPageList',
	// 删除
	dele: '/gh/Elect/delete',
	// 审核
	review: '/gh/Elect/review',
	// 详情
	info: '/gh/Elect/getElect',
}

export {
	request,
	api
}
