<template>
	<div>
		<el-dialog :draggable="true" width="40%" top="5vh" v-model="outerVisible" @close="Reset" title="编辑开会时间">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="开会时间">
						<span class="xx">*</span>
						<el-date-picker @change="change" clearable v-model="ruleForm.meet_time" type="datetime"
							placeholder="请选择开会时间" />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	export default {
		data() {
			return {
				outerVisible: false,
				ruleForm: {
					meet_time: '',
					trade_id: ''
				}
			}
		},
		methods: {
			change(e) {
				if(e) {
					this.ruleForm.meet_time = this.$Moment(e).format('YYYY/MM/DD HH:mm:ss')
				}else {
					this.ruleForm.meet_time = ""
				}
			},
			// 确定
			submitForm() {
				if (this.ruleForm.meet_time === '') {
					ElMessage({
						message: '请选择开会时间',
						type: 'warning',
					})
				} else {
					this.$http.post(api.editsTime, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '编辑成功',
								})
								this.outerVisible = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						})
				}
			},
			// 获取信息
			getTime(id) {
				this.$http.post(api.getTime, {
						trade_id: id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm.meet_time = res.data.meet_time
							this.ruleForm.trade_id = res.data.trade_id
						}
					})
			},
			// 显示隐藏
			show(id) {
				this.outerVisible = !this.outerVisible
				this.getTime(id)
			}
		}
	}
</script>

<style scoped lang="scss">
	video {
		width: 300px;
		height: 250px;
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>