<template>
	<div>
		<el-dialog :draggable="true" width="40%" top="5vh" v-model="outerVisible" @close="Reset" title="拒绝">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="拒绝原因">
						<span class="xx">*</span>
						<el-input type="textarea" clearable v-model="ruleForm.rejection_reason"
							placeholder="请输入拒绝原因"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/result";
	export default {
		data() {
			return {
				outerVisible: false,
				ruleForm: {
					rejection_reason: '',
					id: '',
					status: 2
				}
			}
		},
		methods: {
			change(e) {
				if (e) {
					this.ruleForm.meet_time = this.$Moment(e).format('YYYY/MM/DD HH:mm:ss')
				} else {
					this.ruleForm.meet_time = ""
				}
			},
			// 确定
			submitForm() {
				if (this.ruleForm.rejection_reason === '') {
					ElMessage({
						message: '请输入拒绝原因',
						type: 'warning',
					})
				} else {
					this.$http.post(api.review, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '编辑成功',
								})
								this.outerVisible = false
								// 刷新列表
								this.$emit('SubmitForm')
							}
						})
				}
			},
			// 显示隐藏
			show(id) {
				this.outerVisible = !this.outerVisible
				this.ruleForm.id = id
			}
		}
	}
</script>

<style scoped lang="scss">
	video {
		width: 300px;
		height: 250px;
	}

	.imglist {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		li {
			width: 20%;
			height: 100px;
			padding-right: 10px;
		}
	}
</style>