<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-permission="['resultdel']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入公司名称">
					</el-input>
				</li>
				<li>
					<el-select clearable v-model="form.status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="审核中" value="0"></el-option>
						<el-option label="已通过" value="1"></el-option>
						<el-option label="不通过" value="2"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#488bff" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" />
				<el-table-column property="name" label="公司名称">
					<template #default="scope">
						{{scope.row.trade?scope.row.trade.company_name:''}}
					</template>
				</el-table-column>
				<el-table-column property="time" label="选举时间" />
				<el-table-column property="meet_time" label="开会时间">
					<template #default="scope">
						{{scope.row.trade?scope.row.trade.meet.meet_time:''}}
					</template>
				</el-table-column>
				<el-table-column property="tenure" label="任期" />
				<el-table-column property="date" label="上传报告图片" width="150">
					<template #default="scope">
						<el-image style="width: 100px; height: 100px" :preview-src-list="srcList(scope.row.pics)"
							:src="srcList(scope.row.pics).length>0?srcList(scope.row.pics)[0]:''" />
					</template>
				</el-table-column>
				<el-table-column property="date" label="上传报告Word/PDF" width="140">
					<template #default="scope">
						<el-dropdown v-if="scope.row.annex">
							<el-tag type="danger">下载查看</el-tag>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item v-for="(item,indexs) in fnannex(scope.row.annex)" :key="indexs">
										<div class="fu-li">
											{{item.OriginalName}}
											<el-button @click="download(item.url)" color="#488bff" class="el-white btn"
												style="color: white" size="mini">下载
											</el-button>
										</div>
									</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
						<p v-else>无</p>
					</template>
				</el-table-column>
				<el-table-column width="130" property="rejection_reason" label="不通过原因">
					<template #default="scope">
						<el-tooltip class="box-item" effect="dark" :content="scope.row.rejection_reason" placement="top-start">
							<div class="contents">{{scope.row.rejection_reason}}</div>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column property="status" label="状态">
					<template #default="scope">
						<el-tag v-if="scope.row.status==0">审核中</el-tag>
						<el-tag type="success" v-if="scope.row.status==1">已通过</el-tag>
						<el-tag v-if="scope.row.status==2" type="danger">不通过</el-tag>
					</template>
				</el-table-column>
				<el-table-column property="create_time" width="145" label="创建时间" />
				<el-table-column fixed="right" property="address" label="操作" width="300">
					<template #default="scope">
						<el-button v-permission="['resultinfoinfo']" @click="binresult(scope.row.trade_id)" size="mini">
							查看详情
						</el-button>
						<el-button v-permission="['resulttime']" @click="setTime(scope.row.trade_id)" size="mini">编辑开会时间
						</el-button>
						<el-button v-if="scope.row.status==0" v-permission="['resultpass']" @click="review(scope.row.id)" size="mini">审核通过
						</el-button>
						<el-button v-if="scope.row.status==0" v-permission="['resultrefuse']" @click="refuse(scope.row.id)" size="mini">拒绝
						</el-button>
						<el-button v-permission="['resultsan']" @click="binmember(scope.row.id,scope.row.trade_id)" size="mini">工会委员会
						</el-button>
						<el-button v-permission="['resultjing']" @click="binfunds(scope.row.id,scope.row.trade_id)" size="mini">经费审查委员会
						</el-button>
						<el-button v-permission="['resultfemale']" @click="binworkers(scope.row.id,scope.row.trade_id)" size="mini">
							女职工委员会
						</el-button>
						<el-button v-permission="['resultdel']" size="mini" @click="dele(scope.row.trade_id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 选举结果报告 -->
		<result ref="result" />
		<!-- 工会三委委员 -->
		<member ref="member"></member>
		<!-- 经费审查委员会主任 -->
		<funds ref="funds"></funds>
		<!-- 女职工委员会主任 -->
		<workers ref="workers"></workers>
		<!-- 拒绝 -->
		<refuse ref="refuse" @SubmitForm="getList" />
		<!-- 编辑开会时间 -->
		<setTime ref="setTime" @SubmitForm="getList" />
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/result";
	import result from '@/components/result.vue'
	import member from '@/components/member.vue'
	import funds from '@/components/funds.vue'
	import workers from '@/components/workers.vue'
	import refuse from './components/refuse.vue'
	import setTime from '@/components/setTime.vue'
	export default {
		components: {
			result,
			member,
			funds,
			workers,
			refuse,
			setTime
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					status:""
				},
				tableData: [],
				idlist: [],
			}
		},
		created() {
			if(this.$route.query.status) {
				this.form.status = this.$route.query.status
			}
			this.getList()
		},
		methods: {
			// 开会时间
			setTime(id) {
				this.$refs.setTime.show(id)
			},
			// 拒绝
			refuse(id) {
				this.$refs.refuse.show(id)
			},
			// 审核
			review(id) {
				ElMessageBox.confirm(
						'是否确定审核?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.review, {
								id: id,
								status: 1
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '审核成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			},
			// 女职工委员会主任
			binworkers(id,trade_id) {
				this.$refs.workers.show(id,trade_id)
			},
			// 经费审查委员会主任
			binfunds(id,trade_id) {
				this.$refs.funds.show(id,trade_id)
			},
			// 工会三委委员
			binmember(id,trade_id) {
				this.$refs.member.show(id,trade_id)
			},
			// 查看详情
			binresult(id) {
				this.$refs.result.show(id)
			},
			// 下载
			download(url) {
				window.location.href = url
			},
			// 附件
			fnannex(url) {
				let list = []
				if (url) {
					list = JSON.parse(url)
					return list
				} else {
					return list
				}
			},
			// 图片预览
			srcList(url) {
				let list = []
				if (url) {
					list = url.split(",")
					return list
				} else {
					return list
				}
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.status=  ""
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.trade_id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									trade_id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								trade_id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped lang="scss">
	.contents {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
	.fu-li {
		font-size: 12px;

		.btn {
			margin-left: 10px;
		}
	}
</style>
